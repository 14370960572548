import React, { useState } from "react";
import { FiChevronRight } from "react-icons/fi";
import Button from "../../../components/Button";
import CardFull from "../../../components/Cards/CardFull";
import Filters from "../../../components/Filters";
import ProcessInfo from "../../../components/ProcessInfo";

const Submenu = ({ processQty }) => {
    const [actionFilter, setActionFilter] = useState(false);

    return (
        <>
            <CardFull>
                <Button className="mr-4 font-medium h-10" onClick={() => setActionFilter(!actionFilter)}>
                    filtros <FiChevronRight size="1.1em" />
                </Button>
                <ProcessInfo information={processQty} />
            </CardFull>
            {actionFilter && <Filters setActionFilter={setActionFilter} actionFilter={actionFilter} />}
        </>
    );
};
export default Submenu;
